import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { FCM } from "@ionic-native/fcm/ngx";

@Injectable({
	providedIn: "root"
})
export class AuthService {
	constructor(public afAuth: AngularFireAuth, public firestore: AngularFirestore, public fcm: FCM) { }

	getUser(): firebase.User {
		if (this.afAuth.auth.currentUser) {
			this.firestore.doc(`crewMembers/${this.afAuth.auth.currentUser.uid}`).set({ lastLogin: Date.now() }, { merge: true });
		}
		return this.afAuth.auth.currentUser;
	}

	getPermissions(userId:string){
		return this.firestore.doc(`users/${userId}`).valueChanges();
	}

	getCrewDetails(){
		return this.firestore.doc(`crewMembers/${this.afAuth.auth.currentUser.uid}`).valueChanges();
	}
	loginUser(newEmail: string, newPassword: string): Promise<firebase.auth.UserCredential> {
		return this.afAuth.auth.signInWithEmailAndPassword(newEmail, newPassword).then(userCredential => {
			return userCredential;
			return this.fcm.getToken().then(token => {
				console.log('TCL: AuthService -> constructor -> userCredential.user.uid', userCredential.user.uid)
				return this.firestore.doc(`/crewMembers/${userCredential.user.uid}/tokens/${token}`).set({
					token: true
				}).then(() => {
					return userCredential;
				});
			});
		});
	}

	anonymousLogin(): Promise<firebase.auth.UserCredential> {
		return this.afAuth.auth.signInAnonymously();
	}

	linkAccount(email: string, password: string): Promise<any> {
		const credential = firebase.auth.EmailAuthProvider.credential(email, password);

		return this.afAuth.auth.currentUser.linkAndRetrieveDataWithCredential(credential).then(
			userCredential => {
				this.firestore.doc(`/userProfile/${userCredential.user.uid}`).update({ email });
			},
			error => {
				console.log("There was an error linking the account", error);
			}
		);
	}

	resetPassword(email: string): Promise<any> {
		return this.afAuth.auth.sendPasswordResetEmail(email);
	}

	logoutUser(): Promise<void> {
		return this.afAuth.auth.signOut();
	}
}
