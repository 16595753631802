import { Component, NgZone, AfterViewInit, OnInit } from "@angular/core";
import { Platform, LoadingController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { FCM } from "@ionic-native/fcm/ngx";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { NavController } from '@ionic/angular';
import { AuthService } from "./services/auth.service";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html"
})
export class AppComponent implements OnInit {
	logginIn: boolean = false;
	constructor(private platform: Platform,
		private navCtrl: NavController,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private fcm: FCM,
		private router: Router,
		private ngZone: NgZone,
		private authService: AuthService,
		public loadingCtrl: LoadingController) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
			this.fcm.onNotification().subscribe(data => {
				// console.log('TCL: AppComponent -> initializeApp -> data' + data)
				console.log('TCL: AppComponent -> initializeApp -> data' + data.jobId)
				if (data.wasTapped) {
					console.log("Tapped");

					this.navCtrl.navigateRoot("/job-detail/" + data.jobId);
				} else {
					this.router.navigateByUrl("/job-detail/" + data.jobId);
				}
			});
		});
	}

	ngOnInit() {
		let user = this.authService.getUser();
		if (user) {
			this.logginIn = true;
		}
		this.router.events.subscribe((route) => {
			if (user) {
				this.logginIn = true;
			} else {
				user = this.authService.getUser();
				if (user) {
					this.logginIn = true;
				}
			}

			if (route instanceof NavigationEnd) {
				const func = async () => {
					const loading = await this.loadingCtrl.create();
					await loading.present();
					await loading.dismiss()

				}
				func();
			}


		});
	}

	navigate(location) {

	}
}
