import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: 'login/confirmed-list', redirectTo: 'confirmed-list', pathMatch: 'full' },
  { path: 'login/home', redirectTo: 'home', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmed-list',
    loadChildren: () => import('./confirmed-list/confirmed-list.module').then(m => m.ConfirmedPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'planner',
    loadChildren: () => import('./planner/planner.module').then(m => m.PlannerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'job-detail/:jobId',
    loadChildren: () => import('./pages/job-detail/job-detail.module').then(m => m.JobDetailPageModule),
    canActivate: [AuthGuard],
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
